export class Constants {
  // OIDC constants
  public static clientRoot = `${ location.origin }/`;
  public static apiRoot = `${ location.origin }/api/`;

  // Miscellaneous constants
  public static kiloByteSize = 1024;
  public static mobileDeviceThreshold = 768;
  public static maxPageSize = 50;
  public static maskedWageValue = '****.**';

  // Configuration url
  public static configurationUri = 'api/configuration-properties';

  // API endpoints
  public static apiUrl = 'api/';
  public static readonly attachmentsServiceUrl = `${ Constants.apiUrl }incidents/{incidentId}/attachments`;
  public static readonly authServiceUrl = `${ Constants.apiUrl }auth`;
  public static readonly clientsServiceUrl = `${ Constants.apiUrl }clients`;
  public static readonly configurationServiceUrl = `${ Constants.apiUrl }configuration-properties`;
  public static readonly enumerationsServiceUrl = `${ Constants.apiUrl }enums`;
  public static readonly healthServiceUrl = `${ Constants.apiUrl }health`;
  public static readonly incidentsServiceUrl = `${ Constants.apiUrl }incidents`;
  public static readonly jurisdictionsServiceUrl = `${ Constants.apiUrl }jurisdictions`;
  public static readonly questionnairesServiceUrl = `${ Constants.apiUrl }questionnaires`;
  public static readonly usersServiceUrl = `${ Constants.apiUrl }users`;
  public static readonly businessUnitsUrl = `${ Constants.clientsServiceUrl }/{clientNumber}/business-units/{businessUnitId}`;
  public static readonly startingLocationsUrl = `${ Constants.clientsServiceUrl }/{clientNumber}/user-starting-locations`;
  public static readonly businessUnitAncestorsUrl = `/api/business-units/{businessUnitId}/tree-view`;
  public static readonly policyServiceUrl = `${ Constants.apiUrl }policies`;
  public static readonly preQuestionDataUrl = `${ Constants.apiUrl }pre-question-data`;
  public static readonly employeesUrl = `${ Constants.apiUrl }employees`;
  public static readonly branchesUrl = `${ Constants.apiUrl }branches`;
  public static readonly contactsUrl = `${ Constants.apiUrl }contacts`;
  public static readonly branchRuleExecuteUrl = `${ Constants.apiUrl }rules/UI_BRANCH_LOOKUP_SELECTED/run`;
  public static readonly contactRuleExecuteUrl = `${ Constants.apiUrl }rules/UI_CONTACT_LOOKUP_SELECTED/run`;
  public static readonly vehicleRuleExecuteUrl = `${ Constants.apiUrl }rules/UI_VEHICLE_LOOKUP_SELECTED/run`;
  public static readonly incidentsDuplicateCheckUrl = `${ Constants.apiUrl }duplicate-check`;
  public static readonly vehiclesUrl = `${ Constants.apiUrl }vehicles`;
  public static readonly resetTokenUrl = `${ Constants.apiUrl }auth/reset-token`;
  public static readonly verificationCodeUrl = `${ Constants.apiUrl }auth/verification-code`;


  // Reporter Admin auth endpoint
  public static readonly reporterAdminCookiePath = '/auth';

  // Prompts
  public static readonly reportingLocationPrompt = 'Reporting Location Number';
  public static readonly dateOfLossPrompt = 'Date of Loss';
  public static readonly nonWcBenefitStatePrompt = 'Benefit State';
  public static readonly wcBenefitStatePrompt = 'Benefit State';
  public static readonly buReferenceNumberPrompt = 'Reference Number';

  // LocalStorage Keys
  public static readonly localStorageKeys = {
    reporterUser: 'reporterUser',
    reporterCurrentClient: 'reporterCurrentClient',
    reporterBusinessUnitsSettings: 'reporterBusinessUnitsSettings',
    reporterAccessToken: 'reporterAccessToken',
    reporterConfig: 'reporterConfig',
    ___gbru: '___gbru',
    reporterClientSelected: btoa ( 'reporterClientSelected' ),
    uiConfig: 'uiConfig',
  };

  // UI Config
  public static readonly uiConfigServiceUrl = '/api/custom-ui-configs';
  public static readonly uiConfigDefault = {
    landingPageFooterHtmlSnippet: '',
    landingPageHeaderHtmlSnippet: '<h3>Welcome!</h3>' +
      'ClaimZone Reporter is now GBGO® firstconnect.\n' +
      'For your convenience, please bookmark this page.',
    ssoButtonText: 'LOGIN USING SINGLE SIGN ON',
    whiteLabelLogoUri: 'assets/images/GB_logo.png',
  };
}
